<template lang="pug">
.category-editor.box
  b-icon.handle(icon='arrows-alt' v-if="showHandle")
  b-field(:label='typeLabel + " Name"')
    b-input(custom-class='fix-input-size' required v-model="form.name")
  .field
    multi-select(:value="selectedType" :options="typeClasses" @input="setType" label="name" track-by="id" :placeholder="typeLabel + ' type'")
  b-field(label="Offset Account For" v-if="canHaveLoanAccount")
    b-select(v-model="form.offsetLoanId" placeholder="None" :expanded='true')
      option(:value='null') None
      option(v-for="account in potentialLoanAccounts" :value="account.id") {{ account.name }}
  b-field(label="Offset Account(s)" v-if="canHaveOffsetAccount")
    multi-select(:value="chosenOffsets" @input="updateOffsetIds" :multiple="true" :options="potentialOffsetAccounts" label="name" track-by="id" placeholder="Link offset(s)")
  b-field(:label="'Custom ' + typeLabel + ' Type'" v-if="form.type == 'custom'" message="Used to group your custom holdings in charts")
    b-input(type="text" v-model="form.customName" required placeholder="Custom name..." ref='customNameField')
  .spacer
  .actions
    b-button.is-danger(:disabled="hasEntries" :title="removeTitle" @click="remove" :loading='isRemoving' v-if="!isDirty") Delete
    b-button.is-primary(:disabled="!isDirty || isInvalid" title="Save" @click="save" :loading='isSaving') Save
  b-message(title="Error" type="is-warning" aria-close-label="Close" v-if="errorMessage") {{ errorMessage }}
</template>
<script>
import CategoryEditor from '@/components/CategoryEditor'
import NewAssetDialog from '@/components/NewAssetDialog'
import NewLiabilityDialog from '@/components/NewLiabilityDialog'
import categoryEdit from '@/mixins/categoryEdit'
import offsetLinker from '@/mixins/offsetLinker'

export default {
  components: {
    CategoryEditor,
    NewAssetDialog,
    NewLiabilityDialog
  },
  mixins: [categoryEdit, offsetLinker],
  props: {
    category: Object,
    showHandle: Boolean
  },
  data() {
    return {
      errorMessage: null,
      form: {},
      isRemoving: false,
      isSaving: false
    }
  },
  computed: {
    removeTitle() {
      return this.hasEntries
        ? 'You have entries using this category.'
        : 'Remove Category'
    }
  },
  watch: {
    isDirty() {
      this.$emit('dirty')
    }
  },
  methods: {
    async save() {
      if (this.isSaving || this.isRemoving) return
      this.isSaving = true
      this.errorMessage = null
      try {
        await this.saveCategory(this.form)
        this.$buefy.toast.open('Category updated.')
      } catch (error) {
        this.errorMessage = error.message
      }
      this.isSaving = false
    },
    async remove() {
      if (this.isSaving || this.isRemoving) return
      this.isRemoving = true
      this.errorMessage = null
      try {
        await this.deleteCategory(this.category.id)
        this.$buefy.toast.open('Category removed.')
      } catch (error) {
        this.errorMessage = error.message
        this.isRemoving = false
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.box
  padding-top: 1rem
  margin: 0
  display: flex
  flex-direction: column
  justify-content: flex-start
  position: relative
.actions
  display: grid
  grid-template-columns: 1fr 1fr
  grid-template-rows: 1fr
  grid-gap: 1rem
.help
  margin-left: 0.1rem
.spacer
  flex-grow: 1
.handle
  position: absolute
  top: .75rem
  right: 1rem
  cursor: move
</style>
