import { mapActions, mapState } from 'vuex'
import { isEqual, find } from 'lodash'
import { assetClasses, liabilityClasses } from '@/misc/type_map'

export default {
  computed: {
    ...mapState('entries', ['entries']),
    ...mapState('categories', ['categories']),
    typeLabel() {
      if (this.form.isAsset) return 'Asset'
      return 'Liability'
    },
    typeClasses() {
      if (this.form.isAsset) return assetClasses
      return liabilityClasses
    },
    selectedType() {
      if (!this.form.type) return undefined
      return find(this.typeClasses, type => type.id === this.form.type)
    },
    hasEntries() {
      return !!find(
        this.entries,
        entry =>
          entry.assets.find(asset => asset.categoryId === this.category.id) ||
          entry.liabilities.find(
            liability => liability.categoryId === this.category.id
          )
      )
    },
    isInvalid() {
      if (!this.form.type) return true
      if (!this.form.name.length) return true
      return false
    },
    isDirty() {
      return Object.keys(this.form).some(
        key => !isEqual(this.form[key], this.category[key])
      )
    }
  },
  watch: {
    category: {
      deep: true,
      immediate: true,
      handler(category) {
        this.form = this.$store.getters['categories/editCategoryForm'](category)
      }
    }
  },
  methods: {
    ...mapActions('categories', ['saveCategory', 'deleteCategory']),
    setType(assetClass) {
      if (!assetClass) return
      this.form.type = assetClass.id
      if (assetClass.id === 'custom') {
        this.form.customName = this.form.name
        setTimeout(() => {
          this.$refs.customNameField.focus()
        }, 50)
      }
    }
  }
}
